import React from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import parse from 'html-react-parser';
import classes from './Welcome.style.module.scss';

export default function Welcome({ onStart }) {
  const {
    FLOW_V2_WELCOME_TITLE = 'Welcome ',
    FLOW_V2_WELCOME_DESCRIPTION = 'Please ensure you have your original ID documents with you.<br/>Photocopies or digital ID documents are not acceptable.',
    FLOW_V2_WELCOME_HYPERLINK_TEXT = '',
    FLOW_V2_WELCOME_BUTTON_TEXT = 'Start your identification'
  } = process.env;

  const title = parse(FLOW_V2_WELCOME_TITLE);
  const description = parse(FLOW_V2_WELCOME_DESCRIPTION);
  const hyperlink = parse(FLOW_V2_WELCOME_HYPERLINK_TEXT);
  const buttonText = parse(FLOW_V2_WELCOME_BUTTON_TEXT);

  const buttons = [
    {
      label: buttonText,
      onClick: () => {
        onStart();
      },
      dataTestId: 'welcome-button'
    }
  ];

  return (
    <Page buttons={buttons}>
      <div className={classes.wrapper}>
        <div className={classes.heading} data-testid="welcome-heading">
          {title}
        </div>
        <div className={classes.description} data-testid="welcome-description">
          {description}
        </div>
        <div className={classes.space} />
        <div className={classes.hyperlink} data-testid="welcome-hyperlink">
          {hyperlink}
        </div>
      </div>
    </Page>
  );
}

Welcome.propTypes = {
  onStart: PropTypes.func.isRequired
};
